
$(document).ready(function(){
  
  //Main Page Second Section
  $('.options-item--first').addClass('active');
  $('.options-item').not(':first-of-type').on('mouseenter',function(){
    $('.options-item').removeClass('active');
  });
  $('.options-item').on('mouseleave',function(){
    $('.options-item--first').addClass('active');
  })
  //Lang change
  var currentLang = $('.lang-active');
  
  currentLang.on('click',function(){
    $('.lang-list').toggleClass('active');
  });
  $('.lang-options__item').on('click',function(){
    var thisLang = $(this).text();
    currentLang.text(thisLang);
    $('.lang-list').removeClass('active');
  });

  //Tabs
  var tabsLink = $('.sidebar--tabs .sidebar-options__link'),
      tabsContent = $('.content--tabs .content-tabs__item');

  tabsLink.each(function(ind,el) {
    $(this).attr('data-tab','tab' + ind);
  });
  tabsContent.each(function(ind,el) {
    $(this).attr('data-tab','tab' + ind);
  });
  tabsLink.on('click',function(e){
    e.preventDefault();
    var dataTab = $(this).data('tab');
    tabsLink.closest('.sidebar-options__item').removeClass('active');
    $(this).closest('.sidebar-options__item').addClass('active');

    $('.content--tabs .content-tabs__item').fadeOut();
    $('.content--tabs .content-tabs__item[data-tab='+dataTab+']').fadeIn();
  });
  //Scroll To Block
  $('.downScroll').click(function(e){
    e.preventDefault();
    var target = $(this).attr('href');
    $('html, body').animate({scrollTop: $(target).offset().top}, 800);
    return false;
    });
  
  //Members Slider
  if ($('.members-slider .swiper-container').length > 0) { //some-slider-wrap-in
    let swiperInstances = [];
    $(".members-slider .swiper-container").each(function(index, element){ //some-slider-wrap-in
        const $this = $(this);
        $this.addClass("instance-" + index); //instance need to be unique (ex: some-slider)
        $this.parent().find(".left").addClass("prev-" + index); //prev must be unique (ex: some-slider-prev)
        $this.parent().find(".right").addClass("next-" + index); //next must be unique (ex: some-slider-next)
        swiperInstances[index] = new Swiper(".instance-" + index, { //instance need to be unique (ex: some-slider)
            // your settings ...
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            navigation: {
                prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
            },
        });
    });

    // Now you can call the update on a specific instance in the "swiperInstances" object
    // e.g.
    swiperInstances[0].update();
    //or all of them
    setTimeout(function () {
        for (const slider of swiperInstances) {
            slider.update();
        }
    }, 50);
  }

  //Partners Slider
  var swiper1 = new Swiper('.partners-slider .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: '.partners-slider .right',
      prevEl: '.partners-slider .left',
    },
    autoplay: {
      delay: 1500
    },
    breakpoints: {
      1400: {
        slidesPerView: 4
      },
      992: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      568: {
        slidesPerView: 1,
        autoplay: {
          delay: 3000
        }
      }
    }
  });
  //Members Main Page Slider
  var swiper2 = new Swiper('.ourMembers .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: '.ourMembers .right',
      prevEl: '.ourMembers .left',
    },
    pagination: {
      el: '.ourMembers .swiper-pagination',
      clickable: true
    }
  });
  //FAQ
  $('.faq-trigger,.faq-name').on('click',function(){
    $(this).closest('.faq-top').toggleClass('active');
    $(this).closest('.faq-top').next().slideToggle();
  });

  //Modal Tabs
  var modalTabs = $('.modal-tabs li'),
      modalContent = $('.modal-content');

  modalTabs.each(function(ind,el) {
    $(this).attr('data-tab','tab' + ind);
  });
  modalContent.each(function(ind,el) {
    $(this).attr('data-tab','tab' + ind);
  });
  modalTabs.on('click',function(e){
    e.preventDefault();
    var dataTab = $(this).data('tab');
    $('.modal-tabs li').removeClass('active');
    $(this).addClass('active');

    $('.modal-content').hide();
    $('.modal-content[data-tab='+dataTab+']').toggle();
  });
  //Burger Menu
  $('.burger').on('click',function(){
    $('.mobile-menu').addClass('active');
  });
  //Menu Desktop
  $('.mobile-menu .menu-item>a').on('click',function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('sub').find('.sub-menu').slideToggle();
  });
  //Filter Btn
  $('.filterBtn').on('click',function(e) {
    e.preventDefault();
    $('.overlay').fadeIn();
    $('.filter-block').addClass('active')
  });
  //Consult Btn
  $('.becomeMember').on('click',function(e){
    e.preventDefault();
    $('.overlay,.modal-wrapper--reg').fadeIn();
  })
  //Auth Btn
  $('.auth-btn').on('click',function(e){
    e.preventDefault();
    $('.overlay,.modal-wrapper--auth').fadeIn();
  })
  //Tarif Btn
  $('.tarif-modal').on('click',function(e){
    e.preventDefault();
    $('.overlay,.modal-wrapper--options').fadeIn();
  })
  //Close Modals(click outside)
  $(document).mouseup(function (e){ // событие клика по веб-документу
		var div = $(".modal,.filter-block,.lang-list"); // тут указываем ID элемента
		if (!div.is(e.target) // если клик был не по нашему блоку
		    && div.has(e.target).length === 0) { // и не по его дочерним элементам
          $('.overlay,.modal-wrapper').fadeOut(); // скрываем его
          $('.filter-block,.lang-list').removeClass('active')
		}
	});
  //Close Modals
  $('.modal-close,.closeModalBtn').on('click',function(){
    $('.overlay,.modal-wrapper').fadeOut();
    $('.filter-block,.mobile-menu').removeClass('active');
  });
  //Styler
  $('select').styler();
  //Phone Mask
  $("input[type='tel']").mask("+7 (999) 999-9999");
  //Change order elements
  var ourMembersTitle = $('.ourMembers-row .general-title'),
      ourMembersRow = $('.ourMembers-row');

  if ( $(window).width() < 1400 ) {
    $(ourMembersTitle).appendTo(ourMembersRow);
  }

  //Soviet Text
  $('.soviet-item').each(function(ind,el){
    var preInfoText = $(this).find(".soviet-desc").text();
    if(100 < preInfoText.length){
      var newText = preInfoText.substring(0,100)+"...";
      $(this).find(".soviet-desc").text(newText);
    }
  $(this).find(".moreBtn").on("click",function(e){
    e.preventDefault();
    $(this).closest('.soviet-item').find('.soviet-desc').text(preInfoText);
  });
  });
  

  $('.tarif-item:nth-child(2)').addClass('active');
  $('.tarif-item').not(':nth-child(2)').on('mouseenter',function(){
    $('.tarif-item').removeClass('active');
  });
  $('.tarif-item').on('mouseleave',function(){
    $('.tarif-item:nth-child(2)').addClass('active');
  })

});